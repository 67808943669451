import { AnyMaskedOptions, InputMask } from "imask";

export class PatternInputMask {
    private readonly element: HTMLInputElement
    private readonly interopObject: any
    private readonly mask: IMask.InputMask<IMask.AnyMaskedOptions>;

    constructor(elementId, interopObject, pattern: string) {
        this.element = document.getElementById(elementId) as HTMLInputElement;
        this.interopObject = interopObject;

        const options: AnyMaskedOptions = {
            mask: pattern
        };
        this.mask = new InputMask(this.element, options);

        this.mask.on('accept', () => this.onAccept());
    }

    setValue(value) {
        this.mask.unmaskedValue = value;
    }

    getValue() {
        return this.mask.unmaskedValue;
    }

    private onAccept() {
        this.interopObject.invokeMethodAsync('OnValueChanged', this.mask.unmaskedValue);
    }

    dispose() {
        this.mask.off('accept');
        this.mask.destroy();
    }
}