function ActivityWatcher(el) {
    var canAdd = true;
    var lastTime = new Date();

    function maybeUpdateLastTime() {
        if (!canAdd) {
            return;
        }

        lastTime = new Date();
        canAdd = false;

        setTimeout(function () {
            canAdd = true;
        }, 500);
    }

    function timeSinceLastUpdateMS() {
        var now = new Date();
        var result = now.getTime() - lastTime.getTime();
        return result;
    }

    function stop() {
        el?.removeEventListener('mousemove', maybeUpdateLastTime, true);
    }

    el.addEventListener('mousemove', maybeUpdateLastTime, { capture: true, passive: true });

    return {
        timeSinceLastUpdateMS,
        stop,
        maybeUpdateLastTime
    }
}

export function watchActivity(element: HTMLElement) {

    var watcher = ActivityWatcher(element);
    var clientWindow = (window as any);
    var elementId = element.id;

    if (elementId !== null && clientWindow.addWatcher) {
        clientWindow.addWatcher(elementId, watcher);
    }

    return watcher;
}