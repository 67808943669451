export function LocalStorageProperty(name) {
	function get() {
		return window.localStorage[name];
	}

	function set(value) {
		window.localStorage[name] = value;
	}

	return {
		get,
		set
	};
}